<template>
  <div class="ctoQuotaDrawer">
    <el-drawer
      :visible.sync="drawer"
      size="95%"
      direction="btt"
      @close="drawerClose"
      destroy-on-close
    >
      <template slot="title">
        <slot name="title">
          <div class="ctoQuotaDrawer__title">
            <div class="titleTop">
              {{ partTitle }} / <span>{{ param.title }}</span>
              <span class="app_total_text" v-if="param.searchStatus === 'To Be Approved' && param.position === 'hc'">
                （待审批：{{total}}条）
              </span>
              <!--              <div class="line"></div>-->
            </div>
            <div>
              <el-button v-show="formType == 'cto' ? !(param.ctoApproveStatus === 1 || param.groupCFOApprovalStatus === 1) : !(param.groupCFOApprovalStatus === 1)" role="pass" v-permission type="primary" @click="pass()" :loading="loading">通过</el-button>
              <el-button v-show="formType == 'cto' ? !(param.ctoApproveStatus === 1 || param.groupCFOApprovalStatus === 1) : !(param.groupCFOApprovalStatus === 1)" role="rejected" v-permission @click="showReject()" :loading="loading"
              >驳回</el-button >
            </div>
            <div>
              <el-button @click="next">下一条</el-button>
            </div>
            <!--            <div class="buttonLine">-->
            <!--              <el-button @click="goback">返回</el-button>-->
            <!--            </div>-->
          </div>
        </slot>
      </template>
      <div class="ctoQuotaDrawer__body">
        <div class="ctoQuotaDrawer__content" id="ctoQuotaDrawer__content">
          <HCInformation
            ref="HCInformationRef"
            :params="param"
          />
          <ctoRecord v-if="(param.searchStatus !== 'To Be Approved' && param.position === 'hc') || rejected" ref="financeRecordRef" :hcId ='param.hcId'/>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
import HCInformation from '@/views/quotaManagement/components/HCInformation'
import ctoRecord from "@/views/ctoApproval/ctoRecord";
export default {
  name: 'ctoQuotaDrawer',
  inject: ['formType'],
  props:{
    param:{
      type:Object,
      default:{
        id:'',
        tabCon: 'HCInformation',
        position: 'hc',
        title: 'CTO审批',
        target: 'financial',
        searchStatus:'ALL',
      }
    },
  },
  watch:{
    'param.id': {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true
    },
  },
  components: {
    HCInformation,
    ctoRecord
  },
  created() {
    console.log('详情',this.param);
    this.position = this.param.position
    this.title = this.param.title
    this.status = this.param.status
  },
  computed: {
    statusDisable() {
      return this.status === 'chat'
    },
  },
  data() {
    return {
      tabCon: 'HCInformation',
      position: 'hc',
      title: 'HC 信息',
      partTitle: 'CTO审批',
      drawer: false,
      detailId: '',
      total: 0,
      rejected: false,
      loading: false,
    }
  },
  methods: {
    pass(){
      this.loading = true
      this.$emit('pass',this.param.id)
    },
    showReject(){
      this.detailId=this.param.id
      this.$emit('showReject','')
    },
    next(){
      this.loadingData()
      if(this.param.searchStatus === 'To Be Approved'){
        this.rejected = false
      }
      console.log(this.param.id);
      this.$emit('next',this.param.id)
    },
    loadingData(){
      let loading = this.$loading({ fullscreen: true })
      setTimeout(()=>{
        loading.close()
      },1000)
    },
    reloadDate(){
      if(this.$refs.HCInformationRef){
        this.$refs.HCInformationRef.getData()
      }
      if(this.$refs.financeRecordRef){
        this.$refs.financeRecordRef.getFinanceRecord(this.param.hcId)
      }
    },
    drawerClose(){
      this.detailId=''
    },
  }
}
</script>

<style lang="scss" scoped>
.ctoQuotaDrawer {
  //height: calc(100vh - 60px - 40px);
  background: white;
  overflow: hidden;
  padding: 20px;
  position: relative;
  ::v-deep .el-drawer__header{
    margin-bottom: 20px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    padding: 0 240px 0 80px;
    .titleTop {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
      span {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
      }
    }
  }
  .line {
    height: 1px;
    width: 100%;
    margin: 0;
    background: rgb(224, 227, 234);
    position: absolute;
    left: 0;
    top: 55px;
  }
  .buttonLine {
    position: absolute;
    top: 12px;
    right: 10px;
  }
  &__body{
    margin: 0 100px;
  }
  &__tab {
    margin-bottom: 10px;
  }
  &__content {
    //padding-bottom: 30px;
    //height: calc(100vh - 60px - 40px - 50px - 70px);
    //overflow-y: scroll;
    //overflow-x: hidden;
  }
  .app_total_text{
    font-size: 12px !important;
  }
}
</style>
