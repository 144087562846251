<template>
  <div class="ctoRecord" :style="ctoRecordStyle">
    <div class="descriptions_title">CTO审批记录</div>
    <el-steps direction="vertical">
      <el-step v-for="item in stepsData" status="process" :class="{'isSuccess':item.eventType === 2}">
        <template slot="icon" >
          <i class="step" slot="icon"></i>
        </template>
        <template slot="title" >
          <div class="flex">
            <span class="time_box">{{item.createTime}} </span>
            <div
              v-if="item.eventType === 2"
              class="btn_box success_btn"
            >通过</div>
            <div
              v-if="item.eventType === 3"
              class="btn_box reject_btn"
            >驳回</div>
          </div>
        </template>
        <template slot="description" >
          <div class="description_box" v-if="item.eventType === 3">
            <div>驳回原因：</div>
            <span style="max-width: 800px">
              {{item.operationalOpinions}}
            </span>
          </div>
        </template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
import { getCtoRecord } from "@/api/ctoApproval";

export default {
  name: "ctoRecord",
  props:{hcId:''},
  data(){
    return{
      stepsData:[],
    }
  },
  computed:{
    ctoRecordStyle(){
      return 'height: ' + this.stepsData.length * 100 + 'px';
    }
  },
  created() {
    this.getCtoRecord(this.hcId)
  },
  methods:{
    getCtoRecord(hcId){
      getCtoRecord({hcId:hcId}).then(({code,data})=>{
        if(code === 200){
          console.log(data);
          this.stepsData = data
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.ctoRecord{
  padding-bottom: 60px;
  margin-top: 30px;
  .time_box{
    color: #828282;
    font-size: 12px;
  }
  .btn_box{
    width: 48px;
    height: 20px;
    border-radius: 2px;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    position: relative;
    top: 5px;
    right: 190px;
  }
  .reject_btn{
    background: #DE3934;
  }
  .success_btn{
    background: #6EBAAF;
  }
  .el-steps--vertical{
    margin-left: 60px;
    margin-top: 20px;
  }
  .descriptions_title{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323435;
  }
  .description_box{
    background-color: #F4F4F4;
    max-width: 800px;
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  ::v-deep .el-step__description{
    padding-right: 0;
  }
  ::v-deep .el-step__head{
    width: 6px;
    .el-step__title{
      line-height: 0;
    }
    .el-step__line{
      width: 1px;
      top: 10px;
      bottom: -10px;
      left: 2px;
    }
    .el-step__icon.is-text{
      border: none;
    }
    .el-step__icon{
      background-color: #BA9765;
      width: 5px;
      height: 5px;
    }
  }
  .isSuccess{
    flex-basis: 30% !important;
  }

}
</style>
