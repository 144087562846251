<template>
  <div class="ctoApproval">
    <PocCardTable class="topPocCardTable">
      <template v-slot:headerTitle>
        <div>CTO审批</div>
        <div>
          <el-button @click="resetButton" class="resetButton">
            <i class="el-icon-refresh-left buttonrefreshSpan"></i
          ></el-button>
          <el-button type="primary" size="mini" @click="search">查询</el-button>
        </div></template
      >
      <el-form
        :inline="true"
        :model="formInline"
        size="mini"
        label-position="top"
        class="financialApprovalForm minWidthInput"
      >
        <el-form-item label="HFM Code">
          <el-select
            v-model="hfmCode"
            clearable
            filterable
            @change="getUnit"
            @clear="formInline.hfmCode = []"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hfmCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Job Title">
          <el-input
            v-model="formInline.jobTitle"
            placeholder="Job Title"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="Level2">
          <el-select
            v-model="formInline.assigmentProjectLevel2"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in assigmentProjectLevel2List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Level3">
          <el-select
            v-model="formInline.descriptionLevel3"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in descriptionLevel3List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Client">
          <el-input v-model="formInline.client" clearable />
        </el-form-item>
<!--        <el-form-item label="Labor Code">-->
<!--          <el-select-->
<!--            v-model="formInline.laborCode"-->
<!--            clearable-->
<!--            filterable-->
<!--            placeholder="请选择"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in laborCodeList"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
      </el-form>
    </PocCardTable>
    <PocCardTable>
      <HC ref="hcRef" :formLine="formInline" type="cto" />
    </PocCardTable>
  </div>
</template>

<script>
import HC from '@/views/ctoApproval/HC.vue'
import { getItem, removeItem } from '@/utils/storage'
import { getHfmCodeList } from '@/api/organization'
import {
  getAssigmentProjectLevel2,
  getBusinessUnitNameByHfmCode,
  getDescriptionLevel3
} from '@/api/personnelRequisition'
import { getLaborCode } from '@/api/finance'
export default {
  name: 'ctoApproval',
  provide: {
    formType: 'cto'
  },
  components: {
    HC,
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].style.zoom=0.80;
  },
  created() {
    this.getFormInlineData()
    this.getUnit()
  },
  data() {
    return {
      descriptionLevel3List: [],
      laborCodeList: [],
      hfmCodeList: [],
      assigmentProjectLevel2List: [],
      bussinessUnitList: [],
      formInline: {
        hfmCode: [],
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        client: '',
        laborCode: '',
        department: ''
      },
      hfmCode:'',
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes('RecruitmentQuota')) {
      removeItem('finPoint')
    }
    next()
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].style.zoom=1;
  },
  methods: {
    getUnit() {
      if(this.hfmCode){
        this.formInline.hfmCode = [this.hfmCode]
      }
      const reData = {
        hfmCode: ''
      }
      if (this.hfmCode) {
        reData.hfmCode = this.hfmCode
      }
      getBusinessUnitNameByHfmCode(reData).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.formInline.bussinessUnit = ''
            this.bussinessUnitList = res.data
          } else {
            this.bussinessUnitList = []
          }
        }
      })
    },
    resetButton() {
      this.formInline = {
        hfmCode: [],
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        client: '',
        laborCode: '',
        department: ''
      }
      this.$refs.hcRef.resetPage()
      this.$refs.hcRef.queryApi()
    },
    async getFormInlineData() {
      let hfmCodeList = await getHfmCodeList()
      if (hfmCodeList && hfmCodeList.code === 200) {
        if (hfmCodeList.data) {
          this.hfmCodeList = hfmCodeList.data
        }
      }
      let AssigmentProjectLevel2 = await getAssigmentProjectLevel2()
      if (
        AssigmentProjectLevel2 &&
        AssigmentProjectLevel2.code &&
        AssigmentProjectLevel2.code === 200
      ) {
        if (AssigmentProjectLevel2.data) {
          this.assigmentProjectLevel2List = AssigmentProjectLevel2.data
        }
      }
      let DescriptionLevel3 = await getDescriptionLevel3()
      if (
        DescriptionLevel3 &&
        DescriptionLevel3.code &&
        DescriptionLevel3.code === 200
      ) {
        if (DescriptionLevel3.data) {
          this.descriptionLevel3List = DescriptionLevel3.data
        }
      }
      let LaborCode = await getLaborCode()
      if (LaborCode && LaborCode.code === 200) {
        if (LaborCode.data) {
          this.laborCodeList = LaborCode.data
        }
      }
    },
    search() {
      this.$refs.hcRef.search(this.formInline)
    }
  },
  computed: {}
}
</script>
<style lang="scss" scoped>
.ctoApproval {
  -moz-transform: scale(0.75);
  -moz-transform-origin:0 0;
  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  ::v-deep .el-table th.el-table__cell > .cell{
    word-break: normal;
  }
  :deep(.pcth-title-box) {
    .pcth-title-left {
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        &:first-child {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }
      }
    }
  }
}
</style>
