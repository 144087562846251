import request from '@/utils/request'

export const ctoList = (data) => {
  return request({
    url: '/cto/list',
    method: 'POST',
    data
  })
}
export const getLaborCode = () => {
  return request({
    url: '/cto/getLaborCode'
  })
}
export const approve = (data) => {
  return request({
    url: '/cto/approve',
    method: 'POST',
    data
  })
}
export const reject = (data) => {
  return request({
    url: '/cto/reject',
    method: 'POST',
    data
  })
}
export const ctoGet = (data) => {
  return request({
    url: '/cto/get',
    params: {
      ...data
    }
  })
}
export const getCtoRecord = (data) => {
  return request({
    url: '/cto/getCtoRecord',
    params: {
      ...data
    }
  })
}

export const isReject = (data) => {
  return request({
    url: '/cto/ctoRejectCheck',
    method: 'POST',
    data
  })
}