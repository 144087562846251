<template>
  <div class="HC">
    <el-row>
      <el-radio-group @change="search" v-model="searchStatus">
        <el-radio label="ALL">ALL</el-radio>
        <el-radio label="To Be Approved">待审批</el-radio>
        <el-radio label="APPROVED">已通过</el-radio>
        <el-radio label="REJECTED">已退回</el-radio>
      </el-radio-group>
      <div style="margin-top: 16px; margin-bottom: 12px" class="flexBet">
        <div>
          <el-button v-show="searchStatus !== 'APPROVED'" role="pass" v-permission @click="pass()">通过</el-button>
          <el-button v-show="searchStatus !== 'APPROVED'" role="rejected" v-permission @click="showReject()"
          >驳回</el-button
          >
        </div>
        <div class="total_box">
          <div class="total_text">
            <span>Count：</span>{{count}}
          </div>
          <div class="total_text">
            <span>Total Cost(LC)：</span>{{totalCost}}
          </div>
        </div>
      </div>
    </el-row>
    <PocTable
      ref="tTableRef"
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      :page-size.sync="pageConfig.pageSize"
      stripe
      :max-height="1000000000"
      @selection-change="handleSelectionChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @row-click="rowClick"
      @select-all="selectAll"
      @sort-change="sortChange"
      highlight-current-row
    >
      <el-table-column type="selection" width="50" fixed="left" label-class-name="column_selection" />
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :align="item.align"
        :sortable="item.sortable"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        header-align="center"
      >
        <template v-slot="scope">
          <span v-if="item.key === 'businessCaseToJustifyTheRecruitment'">
            <el-tooltip
              v-if="scope.row[item.key]"
              popper-class="workorder-reason-popper"
              effect="dark"
              :content="scope.row[item.key]"
              placement="top">
                 <span>{{ scope.row[item.key] | ellipsis}}</span>
                </el-tooltip>
            <span v-else>{{scope.row[item.key]}}</span>
          </span>
          <span v-else :class="{'textHover':item.key === 'jobTitle'}" @click="cat(scope.row,item.key === 'jobTitle')">
           {{ ['annualTotalCost','ifBackfillDepartingEmployeeAnnualTotalCost'].includes(item.key) ? currency(scope.row[item.key]): scope.row[item.key] }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Operation"
        width="90"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <svg-icon
            icon="tableChat"
            class="tablePointSvgClass"
            title="查看"
            @click.native="cat(scope.row,true)"
          />
        </template>
      </el-table-column>
    </PocTable>
    <ctoQuotaDrawer ref="ctoQuotaDrawerRef" :param="financialQuotaParam"
                          @pass="pass" @showReject="showReject" @next="next"
    />
    <ctoReject ref="ctoRejectRef" @reject="reject" />
  </div>
</template>

<script>
import { TableColumn } from '@/views/ctoApproval/TableColumn'
import pocSlider from '@/components/poc-slider/poc-slider'
import { approve, ctoList, reject, isReject } from '@/api/ctoApproval'

import { check, typeRange } from '@/utils/util'
import { getItem, setItem } from '@/utils/storage'
import currency from "currency.js";
import ctoQuotaDrawer from "@/views/ctoApproval/ctoQuotaDrawer";
import ctoReject from '@/views/ctoApproval/ctoReject'
export default {
  name: 'HC',
  mixins: [$PCommon.TableMixin],
  props: {
    formLine: {
      require: true
    }
  },
  components: {
    pocSlider,
    ctoQuotaDrawer,
    ctoReject,
  },
  computed:{
    count(){
      return this.selectVal.length
    },
    totalCost(){
      if(this.selectVal.length > 0){
        let cost = Number(0)
        this.selectVal.forEach(a=>{
          cost+= Number(a.annualTotalCost)
        })
        return this.currency(cost)
      }
      return '0.00'
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 600) {
        return value.slice(0, 600) + "...";
      }
      return value;
    }
  },
  watch: {
    formLine: {
      handler(val) {
        if (val) {
          this.formInline = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      columnConfig: TableColumn,
      tableData: [],
      tableDataNext: [],
      searchStatus: 'To Be Approved',
      searchType: '',
      formInline: {},
      selectVal: [],
      maxHeight: '',
      formStatus: '',
      rejectId: '',
      pageNum: 1,
      showDrawer:true,
      isShowTooltip: false,
      pageConfig:{pageSize: 20},
      financialQuotaParam:{
        position:'',
        title:'',
        status:'',
        partTitle:'',
      },
    }
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 445
  },
  mounted() {
    // let body = document.querySelector('.el-card__body')
    // let header = document.querySelector('.el-card__header')
    // let headerHeight = header.clientHeight
    // body.style.height = window.innerHeight - headerHeight - 60 - 67 + 'px'
    this.queryApi()
  },
  methods: {
    currency(value){
      return currency(value,{symbol:''}).format()
    },
    findTablePoint() {
      let finPoint = getItem('finPoint')
      if (finPoint) {
        if (finPoint.id) {
          let tableData = this.tableData.findIndex(
            (item) => item.id === finPoint.id
          )
          if (tableData) {
            this.$refs.tTableRef.setCurrentRow(this.tableData[tableData], true)
          }
        }
      }
    },
    cat(val,flag) {
      console.log('详情信息',val);
      if(!flag){
        return
      }
      const data = {
        position: this.searchType,
        id: val.id
      }
      setItem('finPoint', data)
      this.financialQuotaParam.id = val.id
      this.financialQuotaParam.status = 'chat'
      this.financialQuotaParam.target = 'financial'
      this.financialQuotaParam.searchStatus = this.searchStatus
      this.financialQuotaParam.hcId = val.number
      this.financialQuotaParam.position = 'hc'
      this.financialQuotaParam.title = 'HC 信息'
      this.financialQuotaParam.ctoApproveStatus = val.ctoApproveStatus
      this.financialQuotaParam.groupCFOApprovalStatus = val.groupCFOApprovalStatus
      this.$refs.ctoQuotaDrawerRef.drawer = true
    },
    onConfirm(val) {},
    showReject(){
      if(this.selectVal.length === 0 && !this.$refs.ctoQuotaDrawerRef.detailId){
        this.$message.error('请选择要驳回的数据')
        return
      }
      if(this.selectVal.length > 0) {
        const data = {
          ids: this.selectVal.map((item) => item.id).toString(','),
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        isReject(data).then(res => {
          if (res && res.code == 200) {
            this.$refs.ctoRejectRef.dialogVisible = true
          }
        })
        return
      }
      this.$refs.ctoRejectRef.dialogVisible = true
    },
    reject(val) {
      let detailId = this.$refs.ctoQuotaDrawerRef.detailId
      if(detailId){
        const data = {
          ids: detailId,
          reason: val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        reject(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('驳回成功')
            this.$refs.ctoRejectRef.loading = false
            this.$refs.ctoQuotaDrawerRef.rejected = true
            this.$refs.ctoQuotaDrawerRef.reloadDate()
            this.queryApi()
          }
        })
        return
      }
      if (this.selectVal.length > 0) {
        const data = {
          ids: this.selectVal.map((item) => item.id).toString(','),
          reason: val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        reject(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('驳回成功')
            this.$refs.ctoRejectRef.loading = false
            this.queryApi()
          }
        })
      }
    },
    isSelect() {
      if (this.selectVal.length) {
        return true
      } else {
        this.$message.warning('请选择')
        return false
      }
    },
    pass(val) { //val : 详情页 返回id
      if(val){
        const data = {
          ids: val,
          pageNum: this.pageConfig.pageNum,
          pageSize: this.pageConfig.pageSize,
          type: this.searchType
        }
        approve(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('通过成功')
            if(this.$refs.ctoQuotaDrawerRef){
              this.$refs.ctoQuotaDrawerRef.loading = false
              this.$refs.ctoQuotaDrawerRef.rejected = true
              this.$refs.ctoQuotaDrawerRef.reloadDate()
            }
            this.queryApi()
          }
        })
      }else {
        if (check(this.selectVal) === typeRange.array && this.isSelect()) {
          const data = {
            ids: this.selectVal.map((item) => item.id).toString(','),
            pageNum: this.pageConfig.pageNum,
            pageSize: this.pageConfig.pageSize,
            type: this.searchType
          }
          approve(data).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('通过成功')
              this.queryApi()
            }
          })
        }
      }

    },
    async queryApi() {
      this.loading = true
      this.pageNum = this.pageConfig.pageNum
      const data = {
        ...this.formInline,
        searchType: this.searchType,
        searchStatus: this.searchStatus,
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum
      }
      ctoList(data).then((res) => {
        this.loading = false
        if (res && res.code === 200) {
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.tableDataNext = res.data.list
            this.pageConfig.total = res.data.total
          } else {
            this.tableData = []
            this.pageConfig.total = 0
          }
          if(this.$refs.ctoQuotaDrawerRef){
            this.$refs.ctoQuotaDrawerRef.total = this.pageConfig.total
          }
          this.findTablePoint()
        }
      })
    },
    resetPage() {
      this.formInline = {
        hfmCode: [],
        jobTitle: '',
        assigmentProjectLevel2: '',
        descriptionLevel3: '',
        client: '',
        laborCode: '',
        department: ''
      }
      this.searchStatus = 'ALL'
      this.pageConfig.pageSize = 20
      this.pageConfig.pageNum = 1
      this.formInline.orderColumn = ''
      this.formInline.orderAsc = ''
      this.$refs.tTableRef.clearSort()
    },
    rowClick() {},
    search() {
      this.pageConfig.pageNum = 1;
      this.queryApi()
    },
    next(id){
      let index = 0
      for (let i = 0; i < this.tableDataNext.length; i++) {
        if(this.tableDataNext[i].id === id){
          index = i
        }
      }
      if(index === this.tableDataNext.length -1 ){
        const data = {
          ...this.formInline,
          searchType: this.searchType,
          searchStatus: this.searchStatus,
          pageSize: this.pageConfig.pageSize,
          pageNum: this.pageNum += 1
        }
        ctoList(data).then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            if (res.data && res.data.list && res.data.list.length > 0) {
              this.tableDataNext = res.data.list
              this.financialQuotaParam.id = this.tableDataNext[0].id
              this.financialQuotaParam.hcId = this.tableDataNext[0].number
              this.$refs.ctoQuotaDrawerRef.reloadDate()
            } else {
              this.$message.error('没有更多数据了')
            }
          }
        })
      }else {
        console.log(this.tableDataNext[index + 1].id);
        this.financialQuotaParam.id = this.tableDataNext[index + 1].id
        this.financialQuotaParam.hcId = this.tableDataNext[index + 1].number
        this.$refs.ctoQuotaDrawerRef.reloadDate()
      }

    },
    selectAll() {},
    sortChange(column){
      console.log(column.prop,column.order,'777')
      if(column.order === 'descending' ){
        this.formInline.orderColumn  = column.prop  //  当前排序的字段
        this.formInline.orderAsc = false // 排序或倒序
      }else if(column.order === 'ascending'){
        this.formInline.orderColumn = column.prop
        this.formInline.orderAsc = true
      }else{
        this.formInline.orderColumn = ''
        this.formInline.orderAsc = ''
      }
      this.queryApi()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.HC {
  ::v-deep .el-table td.el-table__cell > .cell{
    word-break: normal;
    line-height: 16px;
  }
  ::v-deep .column_selection{
    margin-left: 2px;
  }
  .textHover {
    font-size: 12px !important;
    font-family: ArialMT;
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
      text-decoration: underline;
    }
  }
  .total_box{
    line-height: 30px;
    display: flex;
    flex-direction: row;
    .total_text{
      font-size: 18px;
      span{
        font-size:14px;
      }
    }
    .total_text:first-child{
      margin-right: 40px;
    }
  }
}
</style>
