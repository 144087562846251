export const TableColumn = [
  {
    key: 'entityHFMCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: 'custom', // 是否可以排序
    align: 'center',
    fixed: false, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'businessUnitName',
    name: 'BU',
    dataType: 'string',
    align: 'center',
    visible: true,
    sortable: 'custom',
    fixed: false,
    width: 150
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    align: 'center',
    visible: true,
    sortable: 'custom',
    fixed: false,
    width: 150
  },
  {
    key: 'jobTitle',
    name: 'Job Title',
    align: 'center',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 90
  },
  {
    key: 'annualTotalCost',
    name: 'Annual Total Cost(LC)',
    dataType: 'string',
    visible: true,
    sortable: false,
    align: 'right',
    fixed: false,
    width: 120
  },

  {
    key: 'ifBackfillDepartingEmployeeAnnualTotalCost',
    name: 'If Backfill Total Cost(LC)',
    dataType: 'string',
    visible: true,
    sortable: false,
    align: 'right',
    fixed: false,
    width: 120
  },

  {
    key: 'type1',
    name: 'Type1',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    align: 'center',
    width: 80
  },
  {
    key: 'businessCaseToJustifyTheRecruitment',
    name: 'Business Case',
    dataType: 'string',
    visible: true,
    sortable: false,
    align: 'center',
    fixed: false,
    width: 500
  },
  {
    key: 'ifBackfillDateOfDeparture',
    name: 'If Backfill,date of departure',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    align: 'center',
    width: 120
  },
  {
    key: 'newCandidateName',
    name: 'New Candidate Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    align: 'center',
    width: 140
  },
]
